const installGoogleAds = () => {
    const elem = document.createElement("script");
    elem.src =
        "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3536996680801897";
    elem.async = true;
    elem.defer = true;

    document.getElementsByTagName('head')[0].appendChild(elem);
};

export default installGoogleAds