import { useState, useEffect } from 'react'

import { getAccountDetails } from '../Api/MyAccount'
import useIsAuthenticated from './useIsAuthenticated'

const useShouldDisplayAds = () => {
    const isAuthenticated = useIsAuthenticated()
    const [shouldDisplayAds, setShouldDisplayAds] = useState(false)

    useEffect(() => {
        const fetchAccountDetails = async () => {
            const response = await getAccountDetails()

            if (response.data.errorType === 'serverUnavailable') {
                setShouldDisplayAds(false)
                return
            }

            if (response?.data?.data?.planId > 2) {
                setShouldDisplayAds(false)
                return
            }

            setShouldDisplayAds(true)
        }

        if (isAuthenticated) {
            fetchAccountDetails()
            return
        } else {
            setShouldDisplayAds(true)
        }

    }, [])

    return shouldDisplayAds
}

export default useShouldDisplayAds